body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* cell editing color change */
/* .p-editable-column */
td.middlepane-editable-row{
  background: #f4e87454 !important;
}

tr.totalsAnnualOpex > td > span{
  font-weight: 600 !important;
}
tr.totalsAnnualOpex {
  background: #a2dff8;
}

.p-dropdown-label {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.52px !important;
  padding: 3px;
}

.p-treeselect-label-container {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-treeselect-trigger {
  width: 10px;
  margin-right: 17px;
}

.p-treeselect-clear-icon {
  width: 10px;
}

.p-dropdown-trigger {
  width: 10px;
  margin-right: 17px;
}

.p-datatable {
  width: 100%;
  .p-sortable-column{
    .p-sortable-column-icon{
      color: #ffff;
    }
  }
}

.table-data-conditional-color > td {
  color: "#00DA09";
  font-family: "Inter";
  font-size: "14px";
  font-weight: "700";
  line-height: "16.94px";
}

.middlepane-non-editable-row {
  pointer-events: none;
  background: #eaebe5;
}

.middlepane-non-editable-row td {
  font-weight: 600 !important;
}

.rsf_middlepane-non-editable-row {
  pointer-events: none;
  background: #eaebe5;
}

.rsf_middlepane-non-editable-row td {
  font-weight: 600 !important;
}
.middlepane-editable-row {
  cursor: pointer;
}

.middlepane-non-editable-row { 
  background: rgb(231 230 230 / 49%) !important;
}

.middlepane-non-editable-row td { 
  background-color: transparent;}

.rsf_middlepane-non-editable-row { 
  background: rgb(200, 200, 200) !important;
}

.rsf_middlepane-non-editable-row td { 
  background-color: transparent;}
  
.leftpane-non-editable-row td {
  font-weight: 600 !important;
  background: rgb(200, 200, 200) !important;
}

.productSku-non-editable-row {
  pointer-events: none;
}

.productSku-non-editable-row td {
  font-weight: 600 !important;
}

.p-tooltip {
  font-size: 11px;
}

.single-item-select  .p-multiselect-header .p-multiselect-select-all {
  display: none !important; /* This hides the entire header, including "select all" */
}

.p-tabview .p-tabview-panels
{
  padding: 0px!important;
  padding-top: 5px!important;
}

/* .data-editable-quarter 
{
  display: none!important;
} */

/* .data-editable-Q2
{
  display: none!important;
} */

/* .data-editable-Q1
{
  display: table-cell!important;
} */

/* .data-editable-Q3
{
  display: none!important;
}

.data-editable-Q4
{
  display: none!important;
} */

.data-quarterly
{
  display: none;
}

.vissible-quarter-Q1 .data-editable-Q1
{
  display: table-cell;
}

.vissible-quarter-Q2 .data-editable-Q2
{
  display: table-cell;
}

.vissible-quarter-Q3 .data-editable-Q3
{
  display: table-cell;
}

.vissible-quarter-Q4 .data-editable-Q4
{
  display: table-cell;
}